/* eslint-disable @typescript-eslint/no-unused-vars */
import { IPermission } from "./permission.maps";

export type TFeatureFlag = IPermission;

// const _ALL = ~0;
const _DOCUMENTS = 0x1;
const _JHA = 0x2;
const _CORRECTIVE_ACTIONS = 0x4;
const _FORMS = 0x8;
const _STANDARDS = 0x10;
const _CONTRACTOR_MANAGEMENT = 0x20;
const _SITE_MANAGEMENT = 0x40;
const _PEOPLE_MANAGEMENT = 0x80;
const _DIVISION_MANAGEMENT = 0x100;
const _TO_DO_LIST = 0x200;
const _PUBLIC_REGISTRATION = 0x400;
const _REPORTS = 0x800;
const _TRAINING_CERT = 0x1000;
const _SYSTEM_EXPORT = 0x2000;
const _DEV_ONLY = 0x4000;
const _TASKS = 0x8000;
const _NOTIFICATIONS = 0x10000;
const _DOCUMENTS_OFFLINE = 0x20000;
const _FORMS_OFFLINE = 0x40000;
const _VIDEO_MANAGEMENT = 0x80000;
const _DASHBOARD = 0x100000;
const _QUEUES = 0x200000;
const _SCHEDULER = 0x400000;
// const _P = 0x800000;
// const _Q = 0x1000000;
// const _R = 0x2000000;
// const _S = 0x4000000;
// const _T = 0x8000000;
// const _U = 0x10000000;
// const _V = 0x20000000;
// const _W = 0x40000000;

// We currently stay within 31 bits so we don't have to chain floats

// eslint-disable-next-line no-shadow
export enum FEATURE_FLAGS {
  "DOCUMENTS" = "DOCUMENTS",
  "JHA" = "JHA",
  "CORRECTIVE_ACTIONS" = "CORRECTIVE_ACTIONS",
  "FORMS" = "FORMS",
  "STANDARDS" = "STANDARDS",
  "CONTRACTOR_MANAGEMENT" = "CONTRACTOR_MANAGEMENT",
  "TRAINING_CERT" = "TRAINING_CERT",
  "SITE_MANAGEMENT" = "SITE_MANAGEMENT",
  "PEOPLE_MANAGEMENT" = "PEOPLE_MANAGEMENT",
  "DIVISION_MANAGEMENT" = "DIVISION_MANAGEMENT",
  "TO_DO_LIST" = "TO_DO_LIST",
  "PUBLIC_REGISTRATION" = "PUBLIC_REGISTRATION",
  "REPORTS" = "REPORTS",
  "SYSTEM_EXPORT" = "SYSTEM_EXPORT",
  "DEV_ONLY" = "DEV_ONLY",
  "TASKS" = "TASKS",
  "QUEUES" = "QUEUES",
  "NOTIFICATIONS" = "NOTIFICATIONS",
  "DOCUMENTS_OFFLINE" = "DOCUMENTS_OFFLINE",
  "FORMS_OFFLINE" = "FORMS_OFFLINE",
  "VIDEO_MANAGEMENT" = "VIDEO_MANAGEMENT",
  "DASHBOARD" = "DASHBOARD",
  "SCHEDULER" = "SCHEDULER",
}

export const SYSTEM_EXPORT: TFeatureFlag = {
  bitFlag: _SYSTEM_EXPORT,
  name: FEATURE_FLAGS.SYSTEM_EXPORT,
};

export const DOCUMENTS: TFeatureFlag = {
  bitFlag: _DOCUMENTS,
  name: FEATURE_FLAGS.DOCUMENTS,
};

export const JHA: TFeatureFlag = {
  bitFlag: _JHA,
  name: FEATURE_FLAGS.JHA,
};

export const CORRECTIVE_ACTIONS: TFeatureFlag = {
  bitFlag: _CORRECTIVE_ACTIONS,
  name: FEATURE_FLAGS.CORRECTIVE_ACTIONS,
};

export const FORMS: TFeatureFlag = {
  bitFlag: _FORMS,
  name: FEATURE_FLAGS.FORMS,
};

export const TASKS: TFeatureFlag = {
  bitFlag: _TASKS,
  name: FEATURE_FLAGS.TASKS,
};

export const STANDARDS: TFeatureFlag = {
  bitFlag: _STANDARDS,
  name: FEATURE_FLAGS.STANDARDS,
};

export const CONTRACTOR_MANAGEMENT: TFeatureFlag = {
  bitFlag: _CONTRACTOR_MANAGEMENT,
  name: FEATURE_FLAGS.CONTRACTOR_MANAGEMENT,
};

export const TRAINING_CERT: TFeatureFlag = {
  bitFlag: _TRAINING_CERT,
  name: FEATURE_FLAGS.TRAINING_CERT,
};

export const SITE_MANAGEMENT: TFeatureFlag = {
  bitFlag: _SITE_MANAGEMENT,
  name: FEATURE_FLAGS.SITE_MANAGEMENT,
};

export const PEOPLE_MANAGEMENT: TFeatureFlag = {
  bitFlag: _PEOPLE_MANAGEMENT,
  name: FEATURE_FLAGS.PEOPLE_MANAGEMENT,
};

export const DIVISION_MANAGEMENT: TFeatureFlag = {
  bitFlag: _DIVISION_MANAGEMENT,
  name: FEATURE_FLAGS.DIVISION_MANAGEMENT,
};

export const TO_DO_LIST: TFeatureFlag = {
  bitFlag: _TO_DO_LIST,
  name: FEATURE_FLAGS.TO_DO_LIST,
};

export const PUBLIC_REGISTRATION: TFeatureFlag = {
  bitFlag: _PUBLIC_REGISTRATION,
  name: FEATURE_FLAGS.PUBLIC_REGISTRATION,
};

export const REPORTS: TFeatureFlag = {
  bitFlag: _REPORTS,
  name: FEATURE_FLAGS.REPORTS,
};

export const DEV_ONLY: TFeatureFlag = {
  bitFlag: _DEV_ONLY,
  name: FEATURE_FLAGS.DEV_ONLY,
};

export const NOTIFICATIONS: TFeatureFlag = {
  bitFlag: _NOTIFICATIONS,
  name: FEATURE_FLAGS.NOTIFICATIONS,
};

export const DOCUMENTS_OFFLINE: TFeatureFlag = {
  bitFlag: _DOCUMENTS_OFFLINE,
  name: FEATURE_FLAGS.DOCUMENTS_OFFLINE,
};

export const FORMS_OFFLINE: TFeatureFlag = {
  bitFlag: _FORMS_OFFLINE,
  name: FEATURE_FLAGS.FORMS_OFFLINE,
};

export const VIDEO_MANAGEMENT: TFeatureFlag = {
  bitFlag: _VIDEO_MANAGEMENT,
  name: FEATURE_FLAGS.VIDEO_MANAGEMENT,
};

export const DASHBOARD: TFeatureFlag = {
  bitFlag: _DASHBOARD,
  name: FEATURE_FLAGS.DASHBOARD,
};

export const QUEUES: TFeatureFlag = {
  bitFlag: _QUEUES,
  name: FEATURE_FLAGS.QUEUES,
};

export const SCHEDULER: TFeatureFlag = {
  bitFlag: _SCHEDULER,
  name: FEATURE_FLAGS.SCHEDULER,
};

export const FLAGS_MAP: Record<FEATURE_FLAGS, TFeatureFlag> = {
  DOCUMENTS,
  JHA,
  CORRECTIVE_ACTIONS,
  FORMS,
  STANDARDS,
  CONTRACTOR_MANAGEMENT,
  SITE_MANAGEMENT,
  PEOPLE_MANAGEMENT,
  DIVISION_MANAGEMENT,
  TO_DO_LIST,
  PUBLIC_REGISTRATION,
  REPORTS,
  TRAINING_CERT,
  SYSTEM_EXPORT,
  TASKS,
  DEV_ONLY,
  NOTIFICATIONS,
  DOCUMENTS_OFFLINE,
  FORMS_OFFLINE,
  VIDEO_MANAGEMENT,
  DASHBOARD,
  QUEUES,
  SCHEDULER,
};

// features that are available to all customers
// FOR NOW ALL FLAGS TO USER DEFAULT
export const defaultEnabledFeatures: TFeatureFlag[] = [
  DOCUMENTS,
  CORRECTIVE_ACTIONS,
  FORMS,
  PEOPLE_MANAGEMENT,
  TRAINING_CERT,
  TO_DO_LIST,
  PUBLIC_REGISTRATION,
  NOTIFICATIONS,
  VIDEO_MANAGEMENT,
  // DASHBOARD
  // QUEUES
  // SITE_MANAGEMENT,
  // CONTRACTOR_MANAGEMENT,
  // DIVISION_MANAGEMENT,
  // REPORTS,
  // STANDARDS,
  // JHA,
];

export const featureIsEnabled = (
  enabledFlags: number,
  testFlag: number,
): boolean => {
  return (enabledFlags & testFlag) === testFlag;
};
