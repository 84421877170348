/**
 * Basic read/admin/super admin that will be associated with an division, tenant, or organization
 */
const _SUPER_ADMIN = ~0;
const _READ = 0x1; // bitFlagIndex = 0
const _ADMIN = 0x2; // bitFlagIndex = 1

/**
 * Permissions configurable at the tenant / division level
 */
const _MANAGE_LINKS = 0x4; // bitFlagIndex = 2
const _ADD_COMMENT = 0x8; // bitFlagIndex = 3
const _EDIT_COMMENT = 0x10; // bitFlagIndex = 4
const _DELETE_COMMENT = 0x20; // bitFlagIndex = 5
const _ASSIGN_USER = 0x40; // bitFlagIndex = 6
const _QUERY_TO_DO_USER = 0x80; // bitFlagIndex = 7
const _MANAGE_TASK = 0x100; // bitFlagIndex = 8
const _MANAGE_OBJECTS = 0x200; // bitFlagIndex = 9
const _MANAGE_PEOPLE = 0x400; // bitFlagIndex = 10
const _MANAGE_TRAINING = 0x800; // bitFlagIndex = 11
const _ADD_ATTACHMENTS = 0x1000; // bitFlagIndex = 12
const _CREATE_DOCUMENT = 0x2000; // bitFlagIndex = 13
const _TRANSITION_DOCUMENT = 0x4000; // bitFlagIndex = 14
const _UPDATE_DOCUMENT = 0x8000; // bitFlagIndex = 15
const _DELETE_DOCUMENT = 0x10000; // bitFlagIndex = 16
const _MANAGE_FORM = 0x20000; // bitFlagIndex = 17
const _MANAGE_SITES = 0x40000; // bitFlagIndex = 18
const _MANAGE_CONTRACTORS = 0x80000; // bitFlagIndex = 19
const _VIEW_FORM_SUBMISSIONS = 0x100000; // bitFlagIndex = 20
const _MANAGE_TO_DO = 0x200000; // bitFlagIndex = 21

/**
 * Currently Unassigned Permissions
 */
// const _UNASSIGNED_10         = 0x400000;
// const _UNASSIGNED_11         = 0x800000;
// const _UNASSIGNED_12         = 0x1000000;
// const _UNASSIGNED_13         = 0x2000000;
// const _UNASSIGNED_14         = 0x4000000;
// const _UNASSIGNED_15         = 0x8000000;
// const _UNASSIGNED_16         = 0x10000000;
// const _UNASSIGNED_17         = 0x20000000;
// const _UNASSIGNED_18         = 0x40000000;

// We currently stay within 31 bits so we don't
// have to chain floats
/**
 *
 */
export interface IPermission {
  bitFlag: number;
  name: string;
}

/**
 * has access to every application features, tenant, and division, within an organization
 */
export const SUPER_ADMIN = {
  bitFlag: _SUPER_ADMIN,
  name: "SUPER_ADMIN",
};

/**
 * has access to read data from division or tenant
 */
export const READ = {
  bitFlag: _READ,
  name: "READ",
};

/**
 * has access to all features within a tenant or division
 */
export const ADMIN = {
  bitFlag: _ADMIN,
  name: "ADMIN",
};

/**
 * has permission to link objects to other objects
 */
export const MANAGE_LINKS = {
  bitFlag: _MANAGE_LINKS,
  name: "MANAGE_LINKS",
};

/**
 * has permission to add comments to an object
 */
export const ADD_COMMENT = {
  bitFlag: _ADD_COMMENT,
  name: "ADD_COMMENT",
};

/**
 * has permission to edit comments of an object
 */
export const EDIT_COMMENT = {
  bitFlag: _EDIT_COMMENT,
  name: "EDIT_COMMENT",
};

/**
 * has permission to delete comments within an object
 */
export const DELETE_COMMENT = {
  bitFlag: _DELETE_COMMENT,
  name: "DELETE_COMMENT",
};

/**
 * has permission to delete an object
 */
export const MANAGE_OBJECTS = {
  bitFlag: _MANAGE_OBJECTS,
  name: "MANAGE_OBJECTS",
};

/**
 * has permission to assign a user to a an object
 */
export const MANAGE_PEOPLE = {
  bitFlag: _MANAGE_PEOPLE,
  name: "MANAGE_PEOPLE",
};

/**
 * has permission to download attachments
 */
export const MANAGE_TRAINING = {
  bitFlag: _MANAGE_TRAINING,
  name: "MANAGE_TRAINING",
};

/**
 * has permission to add attachments to an object
 */
export const ADD_ATTACHMENTS = {
  bitFlag: _ADD_ATTACHMENTS,
  name: "ADD_ATTACHMENTS",
};

/**
 * has permission to create a document
 */
export const CREATE_DOCUMENT = {
  bitFlag: _CREATE_DOCUMENT,
  name: "CREATE_DOCUMENT",
};

/**
 * has permission to transition a document
 */
export const TRANSITION_DOCUMENT = {
  bitFlag: _TRANSITION_DOCUMENT,
  name: "TRANSITION_DOCUMENT",
};

/**
 * has permission to update(edit) a document
 */
export const UPDATE_DOCUMENT = {
  bitFlag: _UPDATE_DOCUMENT,
  name: "UPDATE_DOCUMENT",
};

/**
 * has permission to delete a document
 */
export const DELETE_DOCUMENT = {
  bitFlag: _DELETE_DOCUMENT,
  name: "DELETE_DOCUMENT",
};

export const MANAGE_FORM = {
  bitFlag: _MANAGE_FORM,
  name: "MANAGE_FORM",
};

export const ASSIGN_USER = {
  bitFlag: _ASSIGN_USER,
  name: "ASSIGN_USER",
};

export const QUERY_TO_DO_USER = {
  bitFlag: _QUERY_TO_DO_USER,
  name: "QUERY_TO_DO_USER",
};

export const MANAGE_TASK = {
  bitFlag: _MANAGE_TASK,
  name: "MANAGE_TASK",
};

export const MANAGE_SITES = {
  bitFlag: _MANAGE_SITES,
  name: "MANAGE_SITES",
};

export const MANAGE_CONTRACTORS = {
  bitFlag: _MANAGE_CONTRACTORS,
  name: "MANAGE_CONTRACTORS",
};

export const VIEW_FORM_SUBMISSIONS = {
  bitFlag: _VIEW_FORM_SUBMISSIONS,
  name: "VIEW_FORM_SUBMISSIONS",
};

export const MANAGE_TO_DO = {
  bitFlag: _MANAGE_TO_DO,
  name: "MANAGE_TO_DO",
};

/**
 * THESE ARE UNASSIGNED PERMISSIONS
 */

// const UNASSIGNED_8 = {
//     bitFlag: _UNASSIGNED_8,
//     name: "UNASSIGNED_8"
// }
// const UNASSIGNED_9 = {
//     bitFlag: _UNASSIGNED_9,
//     name: "UNASSIGNED_9"
// }
// const UNASSIGNED_10 = {
//     bitFlag: _UNASSIGNED_10,
//     name: "UNASSIGNED_10"
// }
// const UNASSIGNED_11 = {
//     bitFlag: _UNASSIGNED_11,
//     name: "UNASSIGNED_11"
// }
// const UNASSIGNED_12 = {
//     bitFlag: _UNASSIGNED_12,
//     name: "UNASSIGNED_12"
// }
// const UNASSIGNED_13 = {
//     bitFlag: _UNASSIGNED_13,
//     name: "UNASSIGNED_13"
// }
// const UNASSIGNED_14 = {
//     bitFlag: _UNASSIGNED_14,
//     name: "UNASSIGNED_14"
// }
// const UNASSIGNED_15 = {
//     bitFlag: _UNASSIGNED_15,
//     name: "UNASSIGNED_15"
// }
// const UNASSIGNED_16 = {
//     bitFlag: _UNASSIGNED_16,
//     name: "UNASSIGNED_16"
// }
// const UNASSIGNED_17 = {
//     bitFlag: _UNASSIGNED_17,
//     name: "UNASSIGNED_17"
// }
// const UNASSIGNED_18 = {
//     bitFlag: _UNASSIGNED_18,
//     name: "UNASSIGNED_18"
// }

export const PERMISSION_META = {
  READ: {
    description: "has access to read data from division or client",
  },
  ADMIN: {
    description: "has access to all features within a client or division",
  },
  SUPER_ADMIN: {
    description:
      "has access to every application features, client, and division, within a consultant",
  },
  MANAGE_LINKS: {
    description: "has permission to link objects to other objects",
  },
  ADD_COMMENT: {
    description: "has permission to add comments to an object",
  },
  EDIT_COMMENT: {
    description: "has permission to edit comments of an object",
  },
  DELETE_COMMENT: {
    description: "has permission to delete comments within an object",
  },
  MANAGE_OBJECTS: {
    description: "has permission to create and update objects",
  },
  MANAGE_PEOPLE: {
    description:
      "has permission to access he people management section, view all users, and edit there settings",
  },
  MANAGE_SITES: {
    description:
      "has permission to access all sites and edit all configuration within any site. Site Admin at the Client level",
  },
  MANAGE_TRAINING: {
    description: "has permission to create and edit training records",
  },
  ADD_ATTACHMENTS: {
    description: "has permission to add attachments to an object",
  },
  MANAGE_TASK: {
    description:
      "has permission to create and update task configurations and created issues / corrective actions",
  },
  MANAGE_FORM: {
    description: "has permission to create and update forms configurations",
  },
  CREATE_DOCUMENT: {
    description: "has permission to create a document",
  },
  UPDATE_DOCUMENT: {
    description: "has permission to update(edit) a document",
  },
  DELETE_DOCUMENT: {
    description: "has permission to delete a document",
  },
  TRANSITION_DOCUMENT: {
    description: "has permission to transition a document",
  },
  QUERY_TO_DO_USER: {
    description:
      "has the ability to select and search all users in the to-do list",
  },
  ASSIGN_USER: {
    description:
      "has the ability to assign / re-assign users to forms, and other objects",
  },
  MANAGE_CONTRACTORS: {
    description: "has permission to manage contractors",
  },
  VIEW_FORM_SUBMISSIONS: {
    description: "has permission to view form submissions, but not edit them",
  },
  MANAGE_TO_DO: {
    description:
      "can view in progress forms and self-assign and submit workflow steps",
  },
};

export const PERMISSION_ARRAY = [
  READ,
  ADMIN,
  SUPER_ADMIN,
  MANAGE_LINKS,
  ADD_COMMENT,
  EDIT_COMMENT,
  DELETE_COMMENT,
  MANAGE_OBJECTS,
  MANAGE_PEOPLE,
  MANAGE_TRAINING,
  MANAGE_TASK,
  ADD_ATTACHMENTS,
  MANAGE_FORM,
  QUERY_TO_DO_USER,
  ASSIGN_USER,
  MANAGE_SITES,
  MANAGE_CONTRACTORS,
  VIEW_FORM_SUBMISSIONS,
  MANAGE_TO_DO,
];

export default {
  READ,
  ADMIN,
  SUPER_ADMIN,
  MANAGE_LINKS,
  ADD_COMMENT,
  EDIT_COMMENT,
  DELETE_COMMENT,
  MANAGE_OBJECTS,
  MANAGE_PEOPLE,
  MANAGE_TRAINING,
  MANAGE_TASK,
  ADD_ATTACHMENTS,
  MANAGE_FORM,
  QUERY_TO_DO_USER,
  ASSIGN_USER,
  MANAGE_SITES,
  MANAGE_CONTRACTORS,
  VIEW_FORM_SUBMISSIONS,
  MANAGE_TO_DO,
};
