// libraries
import {
  any,
  array,
  Infer,
  object,
  optional,
  record,
  string,
} from "superstruct";

// types
import { Schema, SchemaTypes } from "./schema";

export type NotificationSchemaTargets =
  | "activeUser"
  | "watcher"
  | { type: "role"; val: string }
  | { type: "uid"; val: string };

export type NotificationSchema = {
  name: string;
  events?: {
    onCreate?: NotificationSchemaTargets[];
    onUpdate?: NotificationSchemaTargets[];
    onAssign?: NotificationSchemaTargets[];
    onComplete?: NotificationSchemaTargets[];
    onExpiration?: NotificationSchemaTargets[];
    onCommentAdded?: NotificationSchemaTargets[];
    onCommentUpdated?: NotificationSchemaTargets[];
    onCommentDeleted?: NotificationSchemaTargets[];
    [key: string]: NotificationSchemaTargets[] | undefined;
  };
};

export function isNotificationSchema(
  data: Schema,
): data is Schema<NotificationSchema> {
  return data.type === SchemaTypes.notification;
}

export const MailAttachment = object({
  filename: optional(string()),
  content: optional(string()),
  path: optional(string()),
  encoding: optional(string()),
  raw: optional(string()),
  href: optional(string()),
  httpHeaders: optional(any()),
  contentDisposition: optional(string()),
  contentType: optional(string()),
  headers: optional(string()),
  cid: optional(string()),
});
export type MailAttachment = Infer<typeof MailAttachment>;

export const Mail = object({
  message: object({
    subject: optional(string()),
    text: string(),
    message: string(),
    html: optional(string()),
  }),
  template: optional(
    object({
      name: string(),
      date: optional(record(string(), any())),
    }),
  ),
  to: array(string()),
  cc: optional(array(string())),
  bcc: optional(array(string())),
  toUids: optional(array(string())),
  ccUids: optional(array(string())),
  bccUids: optional(array(string())),
  from: optional(string()),
  replyTo: optional(string()),
  headers: optional(string()),
  attachments: optional(array(MailAttachment)),
});
export type Mail = Infer<typeof Mail>;

export const Phone = object({
  message: object({
    subject: optional(string()),
    text: string(),
    message: string(),
  }),
  to: array(string()),
  toUids: array(string()),
  link: optional(string()),
});
export type Phone = Infer<typeof Phone>;

export interface NotificationDelivery {
  startTime: FirebaseFirestore.Timestamp;
  endTime: FirebaseFirestore.Timestamp;
  leaseExpireTime: FirebaseFirestore.Timestamp;
  state: "PENDING" | "PROCESSING" | "RETRY" | "SUCCESS" | "ERROR";
  attempts: number;
  error?: string;
  info?: {
    messageId: string;
    accepted: string[];
    rejected: string[];
    pending: string[];
  };
}

export interface MailQueuePayload {
  type: "mail";
  payload: Mail;
  delivery?: NotificationDelivery;
  startTime?: FirebaseFirestore.Timestamp;
  updatedAt?: FirebaseFirestore.Timestamp;
}

export interface TextQueuePayload {
  type: "phone";
  payload: Phone;
  delivery?: NotificationDelivery;
  startTime?: FirebaseFirestore.Timestamp;
  updatedAt?: FirebaseFirestore.Timestamp;
}

export interface InAppQueuePayload {
  type: "inApp";
  payload: {
    message: { subject?: string; message: string };
    toUids: string[];
  };
  delivery?: NotificationDelivery;
  startTime?: FirebaseFirestore.Timestamp;
  updatedAt?: FirebaseFirestore.Timestamp;
}

export type QueuePayload =
  | MailQueuePayload
  | TextQueuePayload
  | InAppQueuePayload;
