import { Frequency, RRule } from "rrule";
import { RRuleOptions, Schedule } from "./scheduler-core";
import { isObject } from "../../utilities";

type Duration = {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
};

export type DateFixed = {
  dtType: "fixed";
  dateTime: string;
};

export type DateRelative = {
  dtType: "relative";
  duration: Duration;
};

// FixedOrRelative
export type DateFixedOrRelative = DateFixed | DateRelative;

export function isFixedDate(dueDate: unknown): dueDate is DateFixed {
  if (!isObject(dueDate)) return false;
  const typ = dueDate["dtType"];
  if (typeof typ !== "string") return false;
  if (typ !== "fixed") {
    return false;
  }
  return typ === "fixed" && typeof dueDate["dateTime"] === "string";
}

export function isRelativeDate(dueDate: unknown): dueDate is DateRelative {
  if (!isObject(dueDate)) return false;
  const typ = dueDate["dtType"];
  if (typeof typ !== "string") return false;
  if (typ !== "relative") {
    return false;
  }
  return typ === "relative" && typeof dueDate["duration"] === "object";
}

export type ScheduleFormDetails = {
  assignedTo?: string[];
  assignedToRole?: string;
  formPerUser?: boolean;
  form: {
    schemaId: string;
  };
  dueDate: DateFixedOrRelative;
};

// We don't explicitly type the taskPayload in schedule-core because the core types are agnostic
// of the contents of the payload. We type it here as an end user of the scheduling system.
export type ScheduleType = Schedule & {
  // Limit the task payload to specific types
  taskPayload: ScheduleFormDetails;
};

export type ScheduleCreateForm = Omit<
  ScheduleType,
  "id" | "recurrence" | "paused" | "pausedUntil"
> & {
  recurrence?: RRuleOptions & {
    bymonth?: string[];
    byweekday?: string[];
    bysetpos?: number;
  };
  recurring?: boolean;
};

export const FrequencySelectOptions = [
  [Frequency.DAILY.toString(), "Day"],
  [Frequency.WEEKLY.toString(), "Week"],
  [Frequency.MONTHLY.toString(), "Month"],
  // [Frequency.QUARTERLY, "Quarter"],
  [Frequency.YEARLY.toString(), "Year"],
].map((v) => ({
  key: v[0].toString(),
  value: v[0],
  label: v[1] as string,
}));

export const WeekdaySelectOptions = [
  [RRule.MO.weekday.toString(), "Monday"],
  [RRule.TU.weekday.toString(), "Tuesday"],
  [RRule.WE.weekday.toString(), "Wednesday"],
  [RRule.TH.weekday.toString(), "Thursday"],
  [RRule.FR.weekday.toString(), "Friday"],
  [RRule.SA.weekday.toString(), "Saturday"],
  [RRule.SU.weekday.toString(), "Sunday"],
].map((v) => {
  return { key: v[0].toString(), value: v[0], label: v[1] as string };
});
